import screenshot from './screenshot2.png';

const Home = () => {
  return (
    <div
      className="hero min-h-screen bg-gradient-to-br from-base-100 from-40% via-base-200 to-primary"
    >
      <div className="hero-overlay hidden bg-opacity-60"></div>
      <div className="hero-content text-center text-neutral-content">
        <div className="max-w-md">
          <h1 className="mb-5 text-5xl font-bold">Welcome to Recap</h1>
          <p className="text-lg mb-2">
            Recapture safety.
          </p>
          <p className="text-lg mb-2">
            Recapture fun.
          </p>
          <p className="text-lg mb-5">
            Recapture your life.
          </p>
          <a className="btn btn-primary" href="/contact">Contact Us</a>
        </div>
        <div className="mockup-phone hidden sm:inline-block">
          <div className="camera"></div>
          <div className="display">
            <img src={screenshot} alt="Screenshot" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;