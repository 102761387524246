import ContactUs from "./ContactUs";
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { useNavigate } from "react-router-dom";


const Contact = () => {
  const form = useRef();
  const navigate = useNavigate();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_5mxfs6h', 'template_wjxmdcr', form.current, {
        publicKey: '9No3QdT74BglwdelL',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          navigate("/");
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <div
      className="hero min-h-screen bg-gradient-to-br from-40% from-base-100 via-base-200 to-primary"
    >
      <div className="hero bg-opacity-60"></div>
      <div className="hero-content text-center text-neutral-content min-w-full">
        <div className="min-w-[30%] space-y-5">
        <form ref={form} onSubmit={sendEmail} className="hidden md:inline-block">
        <div className="flex flex-row space-x-10">
        <label className="input input-bordered flex mb-3 items-center gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              className="h-4 w-4 opacity-70">
              <path
                d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM12.735 14c.618 0 1.093-.561.872-1.139a6.002 6.002 0 0 0-11.215 0c-.22.578.254 1.139.872 1.139h9.47Z" />
            </svg>
            <input name="from_name" type="text" className="grow" placeholder="Name" />
          </label>
          <label className="input input-bordered flex mb-3 items-center gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              className="h-4 w-4 opacity-70">
              <path
                d="M2.5 3A1.5 1.5 0 0 0 1 4.5v.793c.026.009.051.02.076.032L7.674 8.51c.206.1.446.1.652 0l6.598-3.185A.755.755 0 0 1 15 5.293V4.5A1.5 1.5 0 0 0 13.5 3h-11Z" />
              <path
                d="M15 6.954 8.978 9.86a2.25 2.25 0 0 1-1.956 0L1 6.954V11.5A1.5 1.5 0 0 0 2.5 13h11a1.5 1.5 0 0 0 1.5-1.5V6.954Z" />
            </svg>
            <input type="text" name="reply_to" className="grow" placeholder="Return Email"/>
          </label>
          </div>
          <textarea name="message" className="textarea textarea-bordered textarea-lg w-full h-[15vh]" placeholder="Content"></textarea>
          <input type="submit" className="btn btn-primary" href="/" value="Send" />
        </form>
        <form ref={form} onSubmit={sendEmail} className="md:hidden ">
        <label className="input input-bordered flex items-center gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              className="h-4 w-4 opacity-70">
              <path
                d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM12.735 14c.618 0 1.093-.561.872-1.139a6.002 6.002 0 0 0-11.215 0c-.22.578.254 1.139.872 1.139h9.47Z" />
            </svg>
            <input name="from_name" type="text" className="grow" placeholder="Name" />
          </label>
          <label className="input input-bordered flex items-center gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              className="h-4 w-4 opacity-70">
              <path
                d="M2.5 3A1.5 1.5 0 0 0 1 4.5v.793c.026.009.051.02.076.032L7.674 8.51c.206.1.446.1.652 0l6.598-3.185A.755.755 0 0 1 15 5.293V4.5A1.5 1.5 0 0 0 13.5 3h-11Z" />
              <path
                d="M15 6.954 8.978 9.86a2.25 2.25 0 0 1-1.956 0L1 6.954V11.5A1.5 1.5 0 0 0 2.5 13h11a1.5 1.5 0 0 0 1.5-1.5V6.954Z" />
            </svg>
            <input type="text" name="reply_to" className="grow" placeholder="Return Email"/>
          </label>
          <textarea name="message" className="textarea mb-3 inline-block textarea-bordered textarea-lg w-full h-[15vh]" placeholder="Content"></textarea>
          <div className="h-4 pt-4"> </div>
          <input type="submit" className="btn btn-primary mt-5 inline-block pt-3" href="/" value="Send" />
        </form>
        </div>
        </div>
    </div>
  );
};
export default Contact;